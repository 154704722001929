var GalleryFilter = function(params) {
	var me    = this;
	var $     = jQuery;
	me.params = params;

	var setInitFilterOpts = function() {
		if(me.params.active_product_cat !== "") {
			var next = $("#browse-by-filter-desktop").next()[0];
			$("#browse-by-filter-desktop").find(".toggler").html("-");
			$(next).addClass("side-list-opened");
			
			$(next).find(".list-item").filter(function() {
				var slug = $(this).attr("data-slug");
				
				if(slug == me.params.active_product_cat) {
					$(this).addClass("active-link");
				}
			});

			$("#mobile-browse-by-content").find(".filter-mobile").filter(function() {
				var slug = $(this).attr("data-slug");

				if(slug == me.params.active_product_cat) {
					var grandpa = $(this).parent().parent();
					$(grandpa).find(".dot").show();
				}
			});
		}

		if(me.params.active_gender       !== "" || 
		   me.params.active_brands       !== "" || 
		   me.params.active_stone_shapes !== "" ||
           me.params.active_styles       !== ""   ) {
			
            var next = $("#browse-by-filter-filter-by").next()[0];
			
            $("#browse-by-filter-filter-by").find(".toggler").html("-");
			$(next).addClass("side-list-opened");

			if(me.params.active_gender !== "") {
				var next = $("#browse-by-filter-gender").next()[0];
				$("#browse-by-filter-gender").find(".toggler").html("-");
				$(next).addClass("inner-side-list-open");
			
				$(next).find(".list-item").filter(function() {
					var slug = $(this).attr("data-slug");
				
					if(slug == me.params.active_gender) {
						$(this).addClass("active-link");
					}
				});
				
				$("#mobile-filter-gender-row").find(".toggler").html("-");
				var next = $("#mobile-filter-gender-row").find(".mobile-filter").next()[0];	
				$(next).addClass("mobile-sub-row-open");
				
				$(next).find(".mobile-sub-item a").filter(function() {
					var slug = $(this).attr("data-slug");

					if(slug == me.params.active_gender) {
						$(this).find(".dot").show();
					}
				});
			} 
		
			if(me.params.active_stone_shapes !== "") {
				var next = $("#browse-by-filter-stone-shape").next()[0];
				$("#browse-by-filter-stone-shape").find(".toggler").html("-");
				$(next).addClass("inner-side-list-open");
				var stoneShapes = me.params.active_stone_shapes.split(",");

				$(next).find(".list-item").filter(function() {
					var slug = $(this).attr("data-slug");
					
					if(stoneShapes.indexOf(slug) !== -1) {
						$(this).addClass("active-link");
					}
				});

				$("#mobile-filter-stone-shape-row").find(".toggler").html("-");
				var next = $("#mobile-filter-stone-shape-row").find(".mobile-filter").next()[0];	
				$(next).addClass("mobile-sub-row-open");
				
				var arr = me.params.active_stone_shapes.split(",");

				$(next).find(".mobile-sub-item a").filter(function() {
					var slug = $(this).attr("data-slug");

					if(arr.indexOf(slug) !== -1) {
						$(this).find(".dot").show();
					}
				});
			} 

			if(me.params.active_brands !== "") { 
				var next = $("#browse-by-filter-brands").next()[0];
				$("#browse-by-filter-brands").find(".toggler").html("-");
				$(next).addClass("inner-side-list-open");
				var brands = me.params.active_brands.split(",");

				$(next).find(".list-item").filter(function() {
					var slug = $(this).attr("data-slug");

					if(brands.indexOf(slug) !== -1) {
						$(this).addClass("active-link");
					}
				});

				$("#mobile-filter-brand-row").find(".toggler").html("-");
				var next = $("#mobile-filter-brand-row").find(".mobile-filter").next()[0];	
				$(next).addClass("mobile-sub-row-open");
				
				var arr = me.params.active_brands.split(",");

				$(next).find(".mobile-sub-item a").filter(function() {
					var slug = $(this).attr("data-slug");

					if(arr.indexOf(slug) !== -1) {
						$(this).find(".dot").show();
					}
				});
			}

            if(me.params.active_styles !== "") { 
                var next = $("#browse-by-filter-styles").next()[0];
                
                $("#browse-by-filter-styles").find(".toggler").html("-");
                
                $(next).addClass("inner-side-list-open");
                
                var styles = me.params.active_styles.split(",");

                $(next).find(".list-item").filter(function() {
                    var slug = $(this).attr("data-slug");

                    if(styles.indexOf(slug) !== -1) {
                        $(this).addClass("active-link");
                    }
                });

                $("#mobile-filter-style-row").find(".toggler").html("-");
                
                var next = $("#mobile-filter-style-row").find(".mobile-filter").next()[0];  
                
                $(next).addClass("mobile-sub-row-open");
                
                var arr = me.params.active_styles.split(",");

                $(next).find(".mobile-sub-item a").filter(function() {
                    var slug = $(this).attr("data-slug");

                    if(arr.indexOf(slug) !== -1) {
                        $(this).find(".dot").show();
                    }
                });
            }
		}

		if(me.params.active_order_by !== "") { 
			var next = $("#browse-by-filter-sort-by").next()[0];
			$("#browse-by-filter-sort-by").find(".toggler").html("-");
			$(next).addClass("side-list-opened");
		
			$(next).find(".list-item").filter(function() {
				var slug = $(this).attr("data-slug");
			
				if(slug == me.params.active_order_by) {
					$(this).addClass("active-link");
				}
			});

			$("#mobile-sort-by-content").find(".mobile-filter-row").filter(function() {
				$(this).find(".mobile-filter").filter(function() {
					var slug = $(this).attr("data-slug");

					if(slug == me.params.active_order_by) {
						var grandpa = $(this).parent().parent();
						$(grandpa).find(".dot").show();
					}
				});
			});
		}
	}

	var buildParamsAndRedirect = function() {
		var url    = "/gallery";
		var parms  = [];
		var page   = parseInt(me.params.active_page);

		if(me.params.active_product_cat !== "") {
			url = "/product-category/" + me.params.active_product_cat;
		}

		if(me.params.active_page !== "") {
			url += "/page/" + page;
		}

		if(me.params.active_order_by !== "") {
			parms.push("orderby=" + me.params.active_order_by);
		}

		if(me.params.active_gender !== "") {
			parms.push("gender=" + me.params.active_gender);
		}

		if(me.params.active_brands !== "") {
			parms.push("brand=" + me.params.active_brands);
		}

		if(me.params.active_stone_shapes !== "") {
			parms.push("stone-shape=" + me.params.active_stone_shapes);
		}

        if(me.params.active_show !== "") {
            parms.push("show=" + me.params.active_show);
        }

        if(me.params.active_styles !== "") {
            parms.push("style=" + me.params.active_styles);
        }

		url += "?" + parms.join("&");
        
		window.location = url;
	}

	$("body").on("click", ".filter-desktop,.filter-mobile,.show-list-item", function() {
    	var slug   = $(this).attr("data-slug");
    	var filter = $(this).attr("data-filter"); 
    	
    	if(filter == "product_cat") {
    		me.params.active_page = "";

    		if($(this).hasClass("filter-mobile")) {
    			var grandpa = $(this).parent().parent();
    			var dot     = $(grandpa).find(".dot");
    			var display = $(dot).css("display");
    			
    			if(display == "none") {
    				me.params.active_product_cat = slug;
    			}
    			else {
    				me.params.active_product_cat = "";
    			}
    		}
    		else {
    			if($(this).hasClass("active-link")) {
    				me.params.active_product_cat = "";
    			}
    			else {
    				me.params.active_product_cat = slug;
    			}
    		}
    	}
    	else if(filter == "gender") {
    		me.params.active_page = "";
    		if($(this).hasClass("filter-mobile")) {
    			var dot     = $(this).find(".dot");
    			var display = $(dot).css("display");
    			
    			if(display == "none") {
    				me.params.active_gender = slug;
    			}
    			else {
    				me.params.active_gender = "";
    			}
    		}	
    		else {
    			if($(this).hasClass("active-link")) {
    				me.params.active_gender = "";
    			}
    			else {
    				me.params.active_gender = slug;
    			}
    		}
    	}
    	else if(filter == "stone-shape") {
    		me.params.active_page = "";
    		var arr        = me.params.active_stone_shapes.split(",");
    		var stoneShape = slug; 

    		if($(this).hasClass("filter-mobile")) {
    			var dot     = $(this).find(".dot");
    			var display = $(dot).css("display");
    			
    			if(display == "none") {
    				arr.push(stoneShape);

	    			me.params.active_stone_shapes = arr.filter(function(a) {
	    				return a !== "";
	    			}).join(",");
    			}
    			else {
    				me.params.active_stone_shapes = arr.filter(function(a) {
	    				return a !== stoneShape;
	    			}).join(",");
    			}
    		}
    		else {
	    		if($(this).hasClass("active-link")) {
	    			me.params.active_stone_shapes = arr.filter(function(a) {
	    				return a !== stoneShape;
	    			}).join(",");
	    		}
	    		else {
	    			arr.push(stoneShape);

	    			me.params.active_stone_shapes = arr.filter(function(a) {
	    				return a !== "";
	    			}).join(",");
	    		}
    		}
    	}
    	else if(filter == "brands") {
    		me.params.active_page = "";
    		var arr        = me.params.active_brands.split(",");
    		var brand      = slug;

    		if($(this).hasClass("filter-mobile")) {
    			var dot     = $(this).find(".dot");
    			var display = $(dot).css("display");
    			
    			if(display == "none") {
    				arr.push(brand);

    				me.params.active_brands = arr.filter(function(a) {
    					return a !== "";
    				}).join(",");
    			}
    			else {
    				me.params.active_brands = arr.filter(function(a) {
    					return a !== brand;
    				}).join(",");
    			}
    		}
    		else {
    			if($(this).hasClass("active-link")) { 
    				me.params.active_brands = arr.filter(function(a) {
    					return a !== brand;
    				}).join(",");
    			}
    			else {
    				arr.push(brand);

    				me.params.active_brands = arr.filter(function(a) {
    					return a !== "";
    				}).join(",");
    			}
    		}		
    	}
        else if(filter == "styles") {
            me.params.active_page = "";
            var arr        = me.params.active_styles.split(",");
            var style      = slug;
            
            if($(this).hasClass("filter-mobile")) {
                var dot     = $(this).find(".dot");
                var display = $(dot).css("display");
                
                if(display == "none") {
                    arr.push(style);
                    me.params.active_styles = arr.filter(function(a) { return a !== ""; }).join(",");
                }
                else {
                    me.params.active_styles = arr.filter(function(a) { return a !== style; }).join(",");
                }
            }
            else {
                if($(this).hasClass("active-link")) { 
                    me.params.active_styles = arr.filter(function(a) { return a !== style; }).join(",");
                }
                else {
                    arr.push(style);
                    me.params.active_styles = arr.filter(function(a) { return a !== ""; }).join(",");
                }
            }
        }
    	else if(filter == "orderby") {
    		if($(this).hasClass("filter-mobile")) {
    			var grandpa = $(this).parent().parent();
    			var dot     = $(grandpa).find(".dot");
    			var display = $(dot).css("display");
    			
    			if(display == "none") {
    				me.params.active_order_by = slug;
    			}
    			else {
    				me.params.active_order_by = "";
    			}
    		}
    		else {
    			if($(this).hasClass("active-link")) { 
    				me.params.active_order_by = "";
    			}	
    			else {
    				me.params.active_order_by = slug;
    			}
    		}
    	}
        else if(filter == "show") {
            me.params.active_page = "";
            me.params.active_show = slug;
        }

    	buildParamsAndRedirect();
    });

	var hoversDesktop = function() {
      $("body").on("mouseenter", ".gallery-item", function() {
        var desc = $(this).find(".desc");
        $(desc).addClass("fadeIn");
        $(this).addClass("gallery-item-active");
      });

      $("body").on("mouseleave", ".gallery-item", function() {
        var desc = $(this).find(".desc");
        $(desc).removeClass("fadeIn");
        $(this).removeClass("gallery-item-active");
      });
    }

    var hoversMobile = function() {
      $("body").on("touchstart", ".gallery-item", function() {
        $(".gallery-item").removeClass("gallery-item-active");
        $(this).addClass("gallery-item-active");
        $(".desc").removeClass("fadeIn");
        var desc = $(this).find(".desc");
        $(desc).addClass("fadeIn");
      });
    }

    if('ontouchstart' in document.documentElement) {
      hoversMobile();
    }
    else {
      hoversDesktop();
    }

    $("body").on("click", "#mobile-filter-by,#mobile-browse-by,#mobile-sort-by", function() {
      var id        = $(this).attr("id");
      var contentId = id + "-content";
      $(".mobile-filters").hide();
      $("#" + contentId).show();
      $("#mobile-modal").modal("show");
    });

    $("body").on("click", "#mobile-filter-x-out", function() {
      $("#mobile-modal").modal("hide");
    });

    $("body").on("click", ".sidebar-header, .has-sublist", function() {
      var sideList = $(this).next()[0];
      var toggler  = $(this).find(".toggler");
      
      if($(this).hasClass("sidebar-header")) {
        var openClass = "side-list-opened";
      }
      else if($(this).hasClass("has-sublist")) {
        var openClass = "inner-side-list-open";
      }

      $(sideList).toggleClass(openClass);

      if($(sideList).hasClass(openClass)) {
        $(toggler).html("-");
      }
      else {
        $(toggler).html("+");
      }
    });

    $("body").on("click", ".mobile-filter", function() {
      var sideList = $(this).next()[0];
      var toggler  = $(this).find(".toggler");
      
      $(sideList).toggleClass("mobile-sub-row-open");

      if($(sideList).hasClass("mobile-sub-row-open")) {
        $(toggler).html("-");
      }
      else {
        $(toggler).html("+");
      }
    }); 

    if(me.params) { 
        setInitFilterOpts();
    }
}

