  /* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var homeOwl;
var gallery;
var owl;
var owl2;



(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.search-link').on('click', function() {
          $('.dynamic-search-form').fadeToggle();
        });

        if(!$('body').hasClass('engagement-ring-buying-guide')) {
          

          $('.global-header').scrollToFixed({
            preFixed: function() {
              $('body').addClass('fixed-nav');
            },
            fixed: function() {

              // $('.top-fixed-spacer').delay(200).animate({
              //   'height': $('.scroll-to-fixed-fixed').outerHeight()
              // }, 300);
              // $('body').css({
              //   'padding-top': $('.global-header').height()
              // });
            },
            preUnfixed: function() {
              $('body').removeClass('fixed-nav');
              // $('.top-fixed-spacer').delay(100).animate({
              //   'height': 0
              // }, 300);
            },
            limit: 0,
            spacerClass: 'top-fixed-spacer'
              //marginTop: 0,
          });

        }

        $("a").filter(function() {
          return this.hostname && this.hostname.replace('www.', '') !==
            location.hostname.replace('www.', '');
        }).attr('target', '_blank');

        $("a").filter(function() {
          return this.hostname && this.hostname.replace('www.', '') !==
            location.hostname.replace('www.', '');
        }).click(function(e) {
          var link = $(this).attr("href");
          // ga('send', 'event', 'outbound', 'click', link, {
          //   'transport': 'beacon',
          //   'hitCallback': function() {
          //     //document.location = link;
          //   }
          // });
        });


        $('#mailchimpFooter').submit(function(e){
          e.preventDefault();
      		var mailchimpform = $(this);
    
      		$.ajax({
      			url: mailchimpform.attr('action'),
      			type:'POST',
      			data: mailchimpform.serialize(),
      			success:function(data){
              $('#mailchimpFooter .form-group').fadeOut(200);
              $('.mailchimp-thank-you').delay(200).fadeIn(200);
              // send off confirmation to our GA
              ga('send', 'event', 'newsletter', 'signup', 'global footer');
      			}
      		});
      		return false;
      	});

          $('#jewelers-mutual-form').submit(function(e){
            e.preventDefault();
            var url           = "https://pgi-custom-leads.herokuapp.com/submit";
            var mailchimpform = $(this);
            var data          = mailchimpform.serialize();
            $("#jewelers-mutual-thanks").html("Thank You");
            $("#jewelers-mutual-email").val("");

            $.ajax({
              url     : url,
              type    :'POST',
              data    : data,
              success : function(data){
                ga('send', 'event', 'newsletter', 'signup', 'jewelers mutual 101');
              },
              error   : function(data) {} 
            });

            return false;
          });


       //  $('#mailchimpFooter').submit(function(e){
       //    e.preventDefault();
      	// 	var mailchimpform = $(this);
       //    $('#btnSubmit').addClass('disabled');
       //    $('#loadingDark').fadeIn(100);

      	// 	$.ajax({
      	// 		url:mailchimpform.attr('action'),
      	// 		type:'POST',
      	// 		data:mailchimpform.serialize(),
      	// 		success:function(data){
       //        $('#mailchimpFooter .form-group').fadeOut(200);
       //        $('.mailchimp-thank-you').delay(200).fadeIn(200);
       //        // send off confirmation to our GA
       //        ga('send', 'event', 'newsletter', 'signup', 'global footer');
      	// 		}
      	// 	});
      	// 	return false;
      	// });

        $('#newsletterBar').submit(function(e){
          e.preventDefault();
      		var mailchimpform = $(this);
          $('#btnSubmitPop .text').hide();
          $('#btnSubmitPop .loading-light').fadeIn(100);

      		$.ajax({
      			url:mailchimpform.attr('action'),
      			type:'POST',
      			data:mailchimpform.serialize(),
      			success:function(data){
              $('#newsletterBar .form-group').fadeOut(200);
              $('.mailchimp-newsletter-bar-thank-you').delay(200).fadeIn(200);
              // send off confirmation to our GA
              ga('send', 'event', 'newsletter', 'signup', 'newsletter bar');
      			}
      		});
      		return false;
      	});

        // // validate the email signup
        // //
        // $('#emailSignup').validate({
        //
        //   // Specify the validation rules
        //   rules: {
        //     email: {
        //       required: true,
        //       email: true
        //     },
        //     email_confirm: {
        //       required: true,
        //       email: true,
        //       equalTo: '#email'
        //     }
        //   },
        //
        //   // Specify the validation error messages
        //   messages: {
        //     email: "Please enter a valid email address",
        //     email_confirm: {
        //       required: "Please enter a valid email address",
        //       equalTo: "Email confirmation mismatch"
        //     }
        //   },
        //
        //   submitHandler: function(form) {
        //     var form = $('#emailSignup'),
        //       feedback = $(form).serialize(),
        //       action = $(form).attr('action');
        //     //console.log(action);
        //     $.ajax({
        //       url: action,
        //       data: feedback,
        //       type: 'post',
        //       success: function() {
        //         window.location.href = "/thank-you";
        //       }
        //     });
        //   }
        // });


        // validate the email signup
        //
        // $('#emailSignup3').validate({
        //
        //   // Specify the validation rules
        //   rules: {
        //     email: {
        //       required: true,
        //       email: true
        //     },
        //     email_confirm: {
        //       required: true,
        //       email: true,
        //       equalTo: '#email'
        //     }
        //   },
        //
        //   // Specify the validation error messages
        //   messages: {
        //     email: "Please enter a valid email address",
        //     email_confirm: {
        //       required: "Please enter a valid email address",
        //       equalTo: "Email confirmation mismatch"
        //     }
        //   },
        //
        //   submitHandler: function(form) {
        //     var form = $('#emailSignup3'),
        //       feedback = $(form).serialize(),
        //       action = $(form).attr('action');
        //     //console.log(action);
        //     $.ajax({
        //       url: action,
        //       data: feedback,
        //       type: 'post',
        //       success: function() {
        //         dataLayer.push({'event': 'newsletter_fixed_form_success'});
        //         fbq('track', 'Purchase');
        //         createCookie('closedNewsletter', true, 30);
        //         window.location.href = "/thank-you";
        //       }
        //     });
        //   }
        // });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        homeOwl = $('#home-slideshow')

        homeOwl.on("initialized.owl.carousel", function(event) {
          $("#home-slideshow").fadeIn("fast");
        });

        homeOwl.owlCarousel({
          loop            : true,
          margin          : 0,
          responsiveClass : true,
          stagePadding    : 0,
          circular        : true,
          items           : 1,
          mouseDrag       : false,
          touchDrag       : true,
          pullDrag        : false,
          autoplay        : true,
          animateOut      : 'fadeOut',
          animateIn       : 'fadeIn'
        });

        homeOwl.on('changed.owl.carousel', function(event) {
          var page      = event.page.index;    
          var header   = $(".slideshow-box-header-inner")[page];
          $(".slideshow-box-header-inner").removeClass("active");
          $(header).addClass("active");
        });

        $(".slideshow-boxes-holder").on("click", ".slideshow-box,.sl", function() {
          var href = $(this).attr("data-href");
          window.location = href;
        });

        owl1 = $('#engagement-ring-carousel').owlCarousel({
          loop: true,
          margin: 25,
          responsiveClass: true,
          stagePadding: 5,
          circular: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 1,
              nav: false,
              dots: false,
              margin: 20,
            },
            1000: {
              items: 3,
              nav: true,
              loop: true,
              dots: false
            }
          }
        });

        owl2 = $('#wedding-band-carousel').owlCarousel({
          loop: true,
          margin: 25,
          responsiveClass: true,
          stagePadding: 5,
          circular: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 1,
              nav: false,
              dots: false,
              margin: 20,
            },
            1000: {
              items: 3,
              nav: true,
              loop: true,
              dots: false
            }
          }
        });

        $(window).scroll(function() {
          var top = $(this).scrollTop();

          if(top > 1) {
            $("#centered-logo").hide();
            $("#logo-primary-horiz").show();
            jQuery(".menu-item").addClass("less-padding-top ");
          }
          else {
            $("#centered-logo").show(); 
            $("#logo-primary-horiz").hide();
            jQuery(".menu-item").removeClass("less-padding-top");
          }
        });

        
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Why Platinum page
    'why_platinum': {
      init: function() {
        // JavaScript to be fired on the why-platinum page
      },
      finalize: function() {
        // JavaScript to be fired on the why-platinum page, after the init JS

        owl = $('.owl-carousel').owlCarousel({
          loop: true,
          margin: 20,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: false,
              dots: true
            },
            600: {
              items: 2,
              nav: false,
              dots: true
            },
            1000: {
              items: 3,
              nav: false,
              loop: true
            }
          }
        });

      }
    },
    'platinum_jewelry_facts': {
      init: function() {
        // JavaScript to be fired on the why-platinum page
      },
      finalize: function() {
        // JavaScript to be fired on the why-platinum page, after the init JS

        owl = $('.owl-carousel').owlCarousel({
          loop: true,
          margin: 20,
          responsiveClass: true,
          responsive: {
            0: {
              items: 2,
              nav: true
            },
            600: {
              items: 3,
              nav: false,
              nav: true
            },
            1000: {
              items: 3,
              nav: false,
              loop: true
            }
          }
        });

      }
    },

    'metal_matters': {
      init: function() {
        // JavaScript to be fired on the why-platinum page
      },
      finalize: function() {
        // JavaScript to be fired on the why-platinum page, after the init JS

        metal_matters_carousel_1 = $('.mm-carousel-1').owlCarousel({
          loop: true,
          responsiveClass: true,
          autoplayHoverPause: true,
          responsive: {
            0: {
              items: 1,
              nav: false
            },
            600: {
              items: 1,
              nav: false
            },
            1000: {
              items: 1,
              nav: false,
              loop: true
            }
          }
        });

        metal_matters_carousel_2 = $('.mm-carousel-2').owlCarousel({
          loop: true,
          responsiveClass: true,
          autoplayHoverPause: true,
          responsive: {
            0: {
              items: 1,
              nav: false
            },
            600: {
              items: 1,
              nav: false
            },
            1000: {
              items: 1,
              nav: false,
              loop: true
            }
          }
        });


      }
    },

    'single_product': {
      init: function() {
        // JavaScript to be fired on the why-platinum page
      },
      finalize: function() {
        // JavaScript to be fired on the why-platinum page, after the init JS

        owl = $('.product-carousel').owlCarousel({
          loop: true,
          margin: 0,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 1,
              nav: true,
              nav: true
            },
            1000: {
              items: 1,
              nav: true,
              loop: true
            }
          }
        });

        related_carousel = $('.related-carousel').owlCarousel({
          loop: true,
          margin: 30,
          stagePadding: 5,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 3,
              nav: true,
              nav: true
            },
            1000: {
              items: 3,
              nav: true,
              loop: true
            }
          }
        });

      }
    },
    'buying_tip_single': {
      init: function() {
        // JavaScript to be fired on the why-platinum page
      },
      finalize: function() {
        // JavaScript to be fired on the why-platinum page, after the init JS

        related_owl = $('.owl-carousel').owlCarousel({
          loop: true,
          margin: 20,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: false,
              dots: true
            },
            600: {
              items: 2,
              nav: false,
              dots: true
            },
            1000: {
              items: 3,
              nav: false,
              loop: true
            }
          }
        });


      }
    },
    'blog': {
      init: function() {
        // JavaScript to be fired on the why-platinum page
      },
      finalize: function() {
        // JavaScript to be fired on the why-platinum page, after the init JS

        related_owl = $('.owl-carousel').owlCarousel({
          loop: true,
          margin: 20,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: false,
              dots: true
            },
            600: {
              items: 2,
              nav: false,
              dots: true
            },
            1000: {
              items: 3,
              nav: false,
              loop: true
            }
          }
        });


      }
    },
    'single_post': {
      init: function() {
        // JavaScript to be fired on the why-platinum page
      },
      finalize: function() {
        // JavaScript to be fired on the why-platinum page, after the init JS

        content_owl = $('.slides').owlCarousel({
          loop: true,
          margin: 40,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: false
            },
            600: {
              items: 1,
              nav: false
            },
            1000: {
              items: 1,
              nav: false,
              loop: true
            }
          }
        });

        related_owl = $('.owl-carousel').owlCarousel({
          loop: true,
          margin: 40,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: false
            },
            600: {
              items: 3,
              nav: false
            },
            1000: {
              items: 3,
              nav: false,
              loop: true
            }
          }
        });


        $('body').keyup(function(event) {
          // handle cursor keys
          if (event.keyCode == 37) {
            // go left
            content_owl.trigger('prev.owl.carousel');
          } else if (event.keyCode == 39) {
            // go right
            content_owl.trigger('next.owl.carousel');
          }

        });

      }
    },
    'four_tips_finding_perfect_engagement_ring': {
      init: function() {
        // JavaScript to be fired on the why-platinum page
      },
      finalize: function() {
        // JavaScript to be fired on the why-platinum page, after the init JS

        setTimeout(function() {
          $('body').addClass('fixed-blue-bar');
        }, 3000);

        $('.download-slide').on('click', function(event) {
          event.preventDefault();

          $('html, body').animate({
            scrollTop: $('#landingSignup').offset().top
          }, 500);
        });


        // validate the email signup
        //
        // $('#landingSignupForm').validate({
        //
        //   // Specify the validation rules
        //   rules: {
        //     email: {
        //       required: true,
        //       email: true
        //     },
        //     email_confirm: {
        //       required: true,
        //       email: true,
        //       equalTo: '#email'
        //     },
        //     first_name: {
        //       required: true,
        //       email: false
        //     },
        //     last_name: {
        //       required: true,
        //       email: false
        //     }
        //   },
        //
        //   // Specify the validation error messages
        //   messages: {
        //     email: "Please enter a valid email address",
        //     email_confirm: {
        //       required: "Please enter a valid email address",
        //       equalTo: "Email confirmation mismatch"
        //     },
        //     first_name: "Please enter your first name",
        //     last_name: "Please enter your last name"
        //   },
        //
        //   submitHandler: function(form) {
        //
        //     dataLayer.push({'event': 'landing-page-form-submission'});
        //
        //     var form = $('#landingSignupForm'),
        //       feedback = $(form).serialize(),
        //       action = $(form).attr('action');
        //     $.ajax({
        //       url: action,
        //       data: feedback,
        //       type: 'post',
        //       success: function() {
        //         $('#landingSignupForm').fadeOut(400, function() {
        //           $('.thank-you-download').fadeIn(300);
        //           dataLayer.push({'event': 'landing-page-conversion'});
        //           // url = '/pdf/2017_engagement_guide.pdf';
        //           // var win = window.open(url, '_blank');
        //         });
        //       }
        //     });
        //   }
        // });

      }
    },
    'engagement_ring_buying_guide': {
      init: function() {
        // JavaScript to be fired on the why-platinum page
      },
      finalize: function() {
        // JavaScript to be fired on the why-platinum page, after the init JS

        $('.fixed-subnav').scrollToFixed({
          preFixed: function() {
            //$('body').addClass('fixed-nav');
          },
          fixed: function() {

          },
          preUnfixed: function() {
            $('body').removeClass('fixed-nav');
            // $('.top-fixed-spacer').delay(100).animate({
            //   'height': 0
            // }, 300);
          },
          limit: 0,
          spacerClass: 'top-fixed-spacer'
          //,
          //'marginTop': $('.global-header').height()+20
        });


        // $('#emailSignupBG').validate({
        //
        //   // Specify the validation rules
        //   rules: {
        //     email: {
        //       required: true,
        //       email: true
        //     },
        //     email_confirm: {
        //       required: true,
        //       email: true,
        //       equalTo: '#email'
        //     }
        //   },
        //
        //   // Specify the validation error messages
        //   messages: {
        //     email: "Please enter a valid email address",
        //     email_confirm: {
        //       required: "Please enter a valid email address",
        //       equalTo: "Email confirmation mismatch"
        //     }
        //   },
        //


        $('#engagementRingGuideForm').submit(function(e){
          e.preventDefault();
      		var mailchimpform = $(this);
          $('.email-submit').hide();
          $('.spinner').show();
          console.log(mailchimpform.serialize());

      		$.ajax({
      			url:mailchimpform.attr('action'),
      			type:'POST',
      			data:mailchimpform.serialize(),
      			success:function(data){
              $('#engagementRingGuideForm').hide();
              $('.thank-you-msg').fadeIn(400);
              dataLayer.push({'event': 'buying-guide-form-send'});
      			},
            error: function(e) {
              console.log(e);
              $('.email-submit').show();
              $('.spinner').hide();
            }
      		});
      		return false;
      	});


        //   submitHandler: function(form) {
        //     var form = $('#emailSignupBG');
        //     var feedback = $(form).serialize();
        //
        //     var d = new Date;
        //     var expiration = 3600; // 1 hour,
        //     var unixtime = parseInt(d.getTime() / 1000);
        //     var future_unixtime = unixtime + expiration;
        //     var publicKey = "e668e62a84";
        //     var pk = "173d4fca3a6c4dd";
        //     var method = "POST";
        //     var route = "forms/3/submissions";
        //
        //     stringToSign = publicKey + ":" + method + ":" + route + ":" + future_unixtime;
        //     sig = CalculateSig(stringToSign, pk);
        //     var url = '/gravityformsapi/' + route + '?api_key=' + publicKey + '&signature=' + sig + '&expires=' + future_unixtime;
        //     var values = {input_values : {input_1 : $('#emailField').val()}}
        //
        //     values_json = JSON.stringify(values);
        //
        //     $('.email-submit').hide();
        //     $('.spinner').show();
        //
        //
        //     $.ajax({
        //       url: url,
        //       data: values_json,
        //       type: 'post',
        //       success: function(msg) {
        //         $('#emailSignupBG').hide();
        //         $('.thank-you-msg').fadeIn(400);
        //         dataLayer.push({'event': 'buying-guide-form-send'});
        //       },
        //       error: function(e) {
        //         console.log(e);
        //         $('.email-submit').show();
        //         $('.spinner').hide();
        //       }
        //     });
        //   }
        // });


      }
    },
    'woocommerce_page' : {
      init : function() {
        

        

        
      },
      finalize : function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/),
        
        function(i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, 'finalize');
        });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }

  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point