var Quiz = function($) {
	var sendGAEvent = function(category, action, label) { 
  		var tracker = ga.getAll()[0];

		if(tracker) {
		    tracker.send('event', category, action, label);
		}
	}

	var THEME_PATH = "/wp-content/themes/pgi";

	function shuffle(a) {
    	var j, x, i;
    	for(i = a.length - 1; i > 0; i--) {
       	 	j = Math.floor(Math.random() * (i + 1));
        	x = a[i];
        	a[i] = a[j];
        	a[j] = x;
    	}
    	return a;
	}

	function User() {
		this.picks = [];
	}

	function Quiz(opts) {
		var self       = this;
		self.db        = null;
		self.questions = [];
		self.user      = new User();
		self.state     = { currentQuestion : 0 };

		var GA_CATEGORY = "style-quiz";

		if(opts) {
			self.settingsFile = opts.settings;
		}

		if(!self.settingsFile) {
			throw new Error("PGI Quiz has no settings file set.");
		}

		var reload = function() {
			window.location = window.location.href;	
		}

		var buildAnswerDiv = function(answer, i) {
			//cycle pad class to reduce padding on left and right 
			//of answer box

			var padClass = "";

			if(i % 2 == 0) {
				padClass = "padding-right-seven";
			}
			else {
				padClass = "padding-left-seven";
			}

			var path = THEME_PATH + "/dist/images/quiz";
			var html = "<div class='col-lg-6 col-md-6 col-sm-6 col-6 pt-20 pt-xs-10 " + padClass + "'>"; 
			html    += "<div class='answer-box text-center gotham-book fs-18 fs-xs-16' data-answer-id='" + answer.id + "' style='background:url(" + path + "/" + answer.image + "); background-size:cover; background-position:center top;'>";
			html    += "<div class='answer-box-inner'><div class='answer-box-cell'>";
			html    += answer.text;
			html    += "</div></div></div></div>";
			return html;
		}

		var buildQuestionDiv = function(question) {
			var html    = "<div class='row'><div class='question-box side-padding' data-question-id='" + question.id + "'>";
			html       += "<p class='text-center full-width question-title'>" + question.text + "</p>"; 
			html       += "<div class='question-answers'><div class='row'>";
			var answers = shuffle(question.answers);

			for(var i = 0; i < answers.length; i++) {
				var answerId = answers[i];
				var answer   = self.db.answersDict[answerId];

				if(!answer) {
					throw new Error("Whoops. Answer " + answerId + " does not exist for question " + question.id);
				}

				html += buildAnswerDiv(answer, i);
			}

			html     += "</div></div></div></div>";
			return html;
		}

		var buildCategoryBoxImageCol = function(link, imageUrl) {
			var html = "<div class='col-lg-3 col-md-3 col-sm-3 col-3'><div class='quiz-image-box'>";
			html    += "<a href='" + link + "' class='quiz-end-product'>";
			html    += "<img src='" + (THEME_PATH + "/dist/images/quiz/" + imageUrl) + "' alt='alt' class='full-width' />";  
			html    += "</a>";
			html    += "</div></div>";
			return html;
		}

		var getCategorySuggestion = function() {
			var pickCategoryCounts   = {};
			var maxCategoryCount     = -Infinity;
			var numCategoriesWithMax = 0;
			var categories           = [];

			for(var i = 0; i < self.user.picks.length; i++) {
				var pick = self.user.picks[i];

				if(!(pick.category in pickCategoryCounts)) {
					pickCategoryCounts[pick.category] = 0; 
				}

				pickCategoryCounts[pick.category] = pickCategoryCounts[pick.category] + 1;
			}

			for(var category in pickCategoryCounts) {
				if(pickCategoryCounts[category] > maxCategoryCount) {
					maxCategoryCount = pickCategoryCounts[category];
				}
			}

			for(var category in pickCategoryCounts) {
				if(pickCategoryCounts[category] == maxCategoryCount) {
					numCategoriesWithMax = numCategoriesWithMax + 1;
				}
			}

			for(var category in pickCategoryCounts) {
				if(pickCategoryCounts[category] == maxCategoryCount) {
					if(numCategoriesWithMax == 1) {
						categories.push(self.db.categoryDict[category]);
						break;
					}
					else {
						categories.push(self.db.categoryDict[category]);
						
						if(categories.length >= 2) {
							break;
						}
					}
				}
			}

			return categories;
		}

		var quizDone = function() {
			var categories    = getCategorySuggestion();
			var categoryNames = categories.map(function(c) { return c.name; }).join(" & ");
			$(".quiz-ending-category-name").html(categoryNames);
			var html = "";
			var cats = [];
			var text = "";

			if(categories.length == 1) {
				for(var i = 0; i < categories[0].links.length; i++) {
					var link = categories[0].links[i];
					html    += buildCategoryBoxImageCol(link.link, link.image);
				}

				text = categories[0].text;
				
				$(".quiz-ending-category-text").html(text);

				var url = "url(" + THEME_PATH + "/dist/images/quiz/" + categories[0].image + ")";

				$("#quiz-ending-box-1").css({
					"background-image"    : url,
					"background-position" : "center top",
					"background-repeat"   : "no-repeat"
				});

				$("#quiz-picked-styles").html(categories[0].name);

				$("#quiz-ending-box-1").show();

				$("#quiz-picked-link").attr("href", categories[0].gallery_link);
			}
			else {
				for(var i = 0; i < Math.floor(categories[0].links.length / 2); i++) {
					var link = categories[0].links[i];
					html    += buildCategoryBoxImageCol(link.link, link.image);
				} 

				for(var i = 0; i < Math.floor(categories[0].links.length / 2); i++) {
					var link = categories[1].links[i];
					html    += buildCategoryBoxImageCol(link.link, link.image);
				} 

				text = categories[0].text + "<br /><br />" + categories[1].text;				
				$(".quiz-ending-category-text").html(text);
				$("#quiz-picked-styles").html(categories[0].name + " / " + categories[1].name);
				$("#quiz-ending-box-2").show();
			}

			$(".quiz-ending-box-images-row").html(html);

			$("#quiz-body").fadeOut("fast", function() {
				$("#quiz-back").hide();
				$("#quiz-dots").hide();
				$(".browse-gallery").hide();
				$("#quiz-ending").fadeIn("fast", function() {
					checkForMobileWindowScroll("#quiz-ending");
				});
			});
		}

		var moveNext = function() {
			if(self.state.currentQuestion >= self.state.totalQuestions) {
				quizDone();
				return;
			}

			self.owl.trigger("next.owl.carousel");
		}

		var movePrevious = function() {
			if(self.state.currentQuestion > 0) {
				self.state.currentQuestion = self.state.currentQuestion - 1;
				self.owl.trigger("prev.owl.carousel");
			}

			sendGAEvent(GA_CATEGORY, 'click', 'go-back-link');
		}

		var setAnswer = function(answer) {
			self.user.picks[self.state.currentQuestion] = answer;
			self.state.currentQuestion = self.state.currentQuestion + 1;
			moveNext();
		}

		var checkForMobileWindowScroll = function(div) {
			var width = $(window).width();

        	if(width <= 768) {
        		var quizBodyOffset = $(div).offset();
        		var mobileNavbarHeight = $(".navbar-mobile").height();
        		$(window).scrollTop(quizBodyOffset.top - (mobileNavbarHeight + 15));
        	}
		}

		var buildQuizUI = function(cb) {
			var html = "";

			for(var i = 0; i < self.db.questions.length; i++) {
				var question = self.db.questions[i];
				html += buildQuestionDiv(question);
			}

			$("#quiz-body").html(html);
		   	
		   	self.owl = $('#quiz-body');

	        self.owl.on("initialized.owl.carousel", function(event) {});

	        self.owl.owlCarousel({
	          loop            : false,
	          margin          : 0,
	          responsiveClass : true,
	          stagePadding    : 0,
	          circular        : false,
	          items           : 1,
	          mouseDrag       : false,
	          touchDrag       : false,
	          pullDrag        : false,
	          autoplay        : false,
	          animateOut      : 'fadeOut',
	          animateIn       : 'fadeIn',
	          dotsContainer   : '#quiz-dots'
	        });

	        $("#quiz-num-questions").html(self.db.questions.length);

	        $("#quiz-body").on("click", ".answer-box", function() {
	        	var answerId       = $(this).attr("data-answer-id");
	        	var answer         = self.db.answersDict[answerId];
	        	setAnswer(answer);
	        	checkForMobileWindowScroll("#quiz-body");
	        	sendGAEvent('answer-button: ' + answer.text);
	        });

	        $("#quiz").on("click", "#quiz-back", movePrevious);

	        $("#quiz").on("click", "#quiz-begin", function() {
	        	$("#quiz-start").fadeOut("fast", function() {
	        		$("#quiz-title-small").fadeIn("fast");
	        		$("#quiz-body").fadeIn("fast");
	        		$("#quiz-back").fadeIn("fast");
	        		$("#quiz-dots").fadeIn("fast");
	        		checkForMobileWindowScroll("#quiz-body");
	        	});
	        	
	        	sendGAEvent(GA_CATEGORY, 'click', 'begin-screen-button');
	        });

	        $("#quiz").on("click", "#reload-quiz", function() {
	        	sendGAEvent('take-quiz-again');
	        	reload();
	        });

	        $("#quiz-start").fadeIn("fast");

	        $(document).on("click", "#browse-the-gallery", function() {
	        	sendGAEvent(GA_CATEGORY, 'click', 'browse-the-gallery');
	        });

	        $(document).on("click", ".quiz-end-product", function(e) {
	        	var href = $(this).attr("href");
	        	sendGAEvent(GA_CATEGORY, 'click', ('quiz-end-product-link: ' + href));
	        });

	        $(document).on("click", "#quiz-picked-link", function() {
	        	var href = $(this).attr("href");
	        	sendGAEvent(GA_CATEGORY, 'click', ('quiz-picked-link: ' + href));
	        });

	        $(document).on("click", ".share-box", function() {
	        	var href = $(this).attr("href");
	        	sendGAEvent(GA_CATEGORY, 'click', ('end-social-link: ' + href));
	        });
		}

		var setupQuiz = function(cb) {
			// get quiz settings and make db
			$.get(THEME_PATH + "/" + self.settingsFile, function(str) {
				try {
					self.db = JSON.parse(str);
				}
				catch(err) {
					self.db = str;
				}
				// blah
				// create dictionaries for fast lookups
				self.db.categoryDict = {};

				for(var i = 0; i < self.db.categories.length; i++) {
					var category                      = self.db.categories[i];
					self.db.categoryDict[category.id] = category;
				}

				self.db.answersDict = {};

				for(var i = 0; i < self.db.answers.length; i++) {
					var answer                     = self.db.answers[i];
					self.db.answersDict[answer.id] = answer;
				}

				self.state.totalQuestions = self.db.questions.length;

				cb();
			});
		}

		setupQuiz(buildQuizUI);
	}
	
	return Quiz;
}(jQuery);